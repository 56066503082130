import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import TopBar from '../other-components/TopBar';
import ToastDialog from '../dialogs/ToastDialog';
import LoadingDialog from "../dialogs/LoadingDialog/dialog";
import { API_ACCESS_URL, redirectTo } from '../modals/Constants';
import { getStorage, setStorage } from '../modals/Storage';
import styles from "../css/Screens.module.css";
import general from "../css/General.module.css";

function ChangeName() {
  const navigate = useNavigate();
  const [showLoading, setShowLoading] = useState(false);

  const [pageConst, setConstants] = useState({
    pageTitle: "Change Name",
    inNewName: "",
    toastDialogShow: false,
    toastMessage: "",
  });

  const updateLoadingStatus = (data) => {
    setShowLoading(data);
  }

  const updateToastDialogState = (data, msg) => {
    setConstants(previousState => {
      return { ...previousState, toastDialogShow: data }
    });

    setConstants(previousState => {
      return { ...previousState, toastMessage: msg }
    });
  }

  const onInputValChange = (source, data) => {

    if (source == "newname") {
      setConstants(previousState => {
        return { ...previousState, inNewName: data }
      });
    }
  }

  const validateChangePassword = () => {
    const requestAPI = async (url, formData) => {
      try {
        const res = await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Route': 'route-change-name',
            'AuthToken': getStorage("secret"),
          },
          body: JSON.stringify(formData),
        });

        const data = await res.json();
        updateLoadingStatus(false);

        if (data.status_code == "authorization_error") {
          updateToastDialogState(true, "Authorization Error!");
        } else if (data.status_code == "name_error") {
          updateToastDialogState(true, "Name is not correct! try again");
        } else if (data.status_code == "success") {
          updateToastDialogState(true, "Name Changed!");
          setStorage("uname", pageConst.inNewName);
        } else {
          updateToastDialogState(true, "Something went wrong! Please try again!");
        }

      } catch (error) {
        updateLoadingStatus(false);
        updateToastDialogState(true, "There was a technical issue! Please try again!");
      }
    };

    if (pageConst.inNewName.length > 3) {
      updateLoadingStatus(true);
      const formData = { USER_ID: getStorage("uid"), NEW_NAME: pageConst.inNewName };
      requestAPI(API_ACCESS_URL, formData);
    }
  }

  useEffect(() => {
    if (!getStorage("uid")) {
      navigate('/login', { replace: true });
    }
  }, []);

  return (
    <div className="v-center bg-black ovf-hidden">
      <div className='h-100vh res-wth app-bg'>
        <ToastDialog intentData={pageConst} updateState={updateToastDialogState} />
        <LoadingDialog intentData={showLoading} />

        <div className="pr-v ovf-scrl-y hide-sb zoomAnimView">
          <TopBar intentData={pageConst} multiBtn={true} multiBtn1="" multiBtn2="" />

          <div className='col-view w-100 v-center pd-5-15 mg-b-15 mg-t-45'>

            <div
              className={[
                styles.inputView,
                general.colView,
                general.width100,
              ].join(" ")}
            >
              <div className={[general.rowView, general.mgT20px].join(" ")}>
                <img
                  className={[general.heightWidth18px]}
                  src={require("../media/icons/account_active_icon.png")}
                  alt="icon"
                />
                <label className={[general.colorWhite, general.mgL10px].join(" ")}>Account Name</label>
              </div>

              <div className={[general.posRelative, general.mgT10px].join(" ")}>
                <input
                  type="text"
                  className={[
                    general.inputBox,
                    general.height50px,
                    general.borderRadius10px,
                    general.bgCustomColor6,
                  ].join(" ")}
                  placeholder="New Name"
                  autoComplete="new-password"
                  onChange={(e) => onInputValChange("newname", e.target.value)}
                ></input>
              </div>
            </div>

            <div className={[
              general.height50px,
              general.width100,
              general.viewCenter,
              general.colorWhite,
              general.fontS20Px,
              general.mgT20px,
              general.borderRadius,
              general.bgCustomColor2,
            ].join(" ")}
              onClick={() => validateChangePassword()}>
              <span>Change Name</span>
            </div>

          </div>

        </div>

      </div>
    </div>
  );
}

export default ChangeName;